<template>
  <Card :class="cardClass">
    <h2 :class="titleCass">
      {{ $t('wallet.transaction.status.progress') }}
    </h2>

    <section :class="statusClass">
      <PercentageBar
        class="status-percentage"
        :percentage="percentage"
        :text="text"
        height="28px"
      />  

      <ButtonV2
        v-if="hasCancelOrder"
        :label="$t('wallet.transaction.status.cancel_trigger')"
        testId="btn-cancel_trigger"
        version="destructive"
        size="small"
        @onClick="onCancelOrder"
      />
    </section>
  </Card>
</template>

<script>
import { Card, PercentageBar } from '@/components/misc';
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'TransactionStatus',

  components: {
    ButtonV2,
    Card,
    PercentageBar,
  },

  props: {
    percentage: {
      type: Number,
      required: true,
    },

    text: {
      type: String,
      required: true,
    },

    hasCancelOrder: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    cardClass() {
      return this.isMobileDevice
        ? 'card-overwrite'
        : 'p-s24';
    },
    
    titleCass() {
      return this.isMobileDevice
        ? 'body-text-medium text-text-inactive mb-s12'
        : 'subheadline-large pb-s16 mb-s16 border-b border-border';
    },
    
    statusClass() {
      return this.hasCancelOrder
        ? 'status-bar'
        : '';
    },
  },

  methods: {
    onCancelOrder() {
      this.$emit('onCancelOrder');
    }
  }
};
</script>

<style scoped>
.status-bar {
  @apply grid gap-s24;
  grid-template-columns: 1.75fr 0.25fr;
}

.card-overwrite {
  border: none !important;
  padding: none !important;
  background: none !important;
}
</style>

<style>
.status-percentage .percentage-bar-label {
  font-size: 13px;
}
</style>
