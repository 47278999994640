<template>
  <Card :class="cardClass">
    <h2 v-if="showTitle" class="details-title">
      {{ title.value }}
    </h2>

    <ul>
      <li
        v-for="(detail, index) in details" :key="index"
        :class="[detailRowClasses]"
      >
        <section v-for="(item, i) in detail" :key="i">
          <p class="body-text-medium text-text-inactive mb-s4">
              {{ item.label }}
          </p>

          <p class="subheadline-small">
            {{ item.value }}
          </p>
        </section>
      </li>
    </ul>
  </Card>
</template>

<script>
import { Card } from '@/components/misc';

export default {
  name: 'TransactionDetails',

  components: { Card },

  props: {
    title: {
      value: String,
      hideOnMobile: Boolean,
    },
    
    details: {
      type: Array,
      required: true,
    },

    isRow: {
      type: Boolean,
      default: false
    },

    isBorderless: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    cardClass() {
      return this.isMobileDevice && this.isBorderless
        ? 'card-overwrite'
        : 'p-s24';
    },

    showTitle() {
      if (!this.title.value) {
        return false;
      }
      
      if (!this.title.hideOnMobile) {
        return true;
      }

      return !this.isMobileDevice;
    },

    detailRowClasses() {
      const borderlessLayout = this.isBorderless 
        ? 'details-row-borderless' 
        : '';

      return this.isRow
        ? `details-row mb-s12 pb-s12 border-b border-border last:border-b-0 ${borderlessLayout}`
        : `details-row mb-s24 ${borderlessLayout}`;
    }
  }
};
</script>

<style scoped>
.details-title {
  @apply subheadline-large pb-s16 mb-s16 border-b border-border;
}

.details-row {
  @apply gap-n-md grid;
  grid-template-columns: 1fr 1fr;
}

.details-row-borderless {
  grid-template-columns: 1fr 1fr 1fr;
}

@media (min-width: 550px) {
  .details-row {
    grid-template-columns: repeat(4, 1.25fr);
  }
}

.details-row:last-child {
  @apply mb-0;
}

.card-overwrite {
  border: none !important;
  padding: none !important;
  background: none !important;
}
</style>
