<template>
  <LayoutMainPages>
    <section v-if="transaction" class="transaction">
      <main>
        <TransactionHeader
          v-if="hasTransactionHeader"
          v-bind="headerProps" 
          class="mb-s32" 
        />

        <TransactionDetails
          v-bind="detailsProps" 
          class="mb-s16"
        />

        <TransactionStatus
          v-if="isActiveOrder"
          v-bind="statusProps"
          @onCancelOrder="onCancelOrder"
          :class="[statusProps.class]"
        />

        <TransactionDetails
          v-if="hasTransactionDetails"
          v-bind="ordersProps" 
          class="mb-s16"
        />
      </main>

      <aside v-if="!isMobileDevice" class="mt-s32">
        <Carousel
          v-if="isOrder"
          class="mt-s24"
          imageType="trade_widget"
          :values="assetImages"
        />
      </aside>
    </section>
  </LayoutMainPages>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { capitalizeWord, formatMoney } from '@/utils/formatters';
import TransactionsListItemMixin from '@/modules/walletv2/WalletTransactions/TransactionsListItem/transactions-list-item.mixin.js';

import {
  Carousel,
  LayoutMainPages,
} from '@/components/misc';

import {
  TransactionDetails,
  TransactionHeader,
  TransactionStatus,
} from '@/modules/walletv2/WalletTransactions/Transaction';


export default {
  name: 'Transaction',

  mixins: [TransactionsListItemMixin],

  components: {
    Carousel,
    LayoutMainPages,
    TransactionDetails,
    TransactionHeader,
    TransactionStatus,
  },

  async mounted() {
    this.scrollToTop();

    if (this.$route.params.id) {
      await this.retrieveSingleTransaction(this.$route.params.id);
    }
  },

  computed: {
    ...mapGetters({ listItem: 'wallet/getSingleTransaction' }),

    isOrder() {
      return this.listItem.__typename === 'UserOrderReceipt';
    },

    isOrderCompleted() {
      return this.isOrder && this.listItem.order_status === 'completed';
    },

    hasTransactionDetails() {
      const { trade_type, is_buy } = this.listItem;
      const isMarketBuy = trade_type === 'instant' && !!is_buy;

      return this.isOrder && !isMarketBuy && !!this.ordersProps.details.length;
    },

    hasTransactionHeader() {
      return this.listItem.__typename !== 'FracPackReceipt';
    },

    singleTransactionType() {
      let type;

      switch (this.listItem.__typename) {
          case 'UserOrderReceipt':
          type = this.$t(`wallet.transaction.details.type.${ this.getUserOrderType() }`);
          break;
        case 'DropReceipt':
          type = this.$t('wallet.transaction.details.type.drop_buy');
          break;
        case 'FracPackReceipt':
          type = this.$t('wallet.transaction.details.type.frack_pack');
          break;
        case 'CirclePaymentReceipt':
          type = this.transaction.name;
          break;
        case 'CircleWithdrawalReceipt':
          type = this.transaction.name;
          break;
      }

      return type;
    },

    headerProps() {
      return {
        type: this.singleTransactionType,
        title: this.transaction.name,
        subtitle: this.transaction.description,
        thumbnail: this.isOrder ? this.listItem.pair.img : ''
      };
    },

    statusProps() {
      let status;

      if (this.isActiveOrder) {
       status = {
          percentage: this.transaction.status.percentage,
          text: this.transaction.status.label,
          hasCancelOrder: this.isActiveOrder,
          class: this.isMobileDevice ? 'mb-s32' : 'mb-s16',
        };
      }

      return status;
    },

    detailsProps() {
      let details;

      switch (this.listItem.__typename) {
          case 'UserOrderReceipt':
          details = this.getOrdersDetails();
          break;
        case 'DropReceipt':
          details = this.getOrdersDetails();
          break;
        case 'FracPackReceipt':
          details = 'frack_pack';
          break;
        default:
          details = this.getDepositWithdrawalDetails();
      }

      return details;
    },

    ordersProps() {
      let orders = [];
      if (this.isOrder) {
        orders = this.listItem.order_fills.map(order => {
          return [
              {
                label: this.$t('wallet.transaction.orders.item.order_value'),
                value: this.numberFormat(order.filled_quote_amount, 2, true, false),
              },
              {
                label: this.$t('wallet.transaction.orders.item.asset_quantity'),
                value: this.getAssetQtt(order.filled_base_amount),
              },
              {
                label: this.$t('wallet.transaction.orders.item.asset_price'),
                value: this.numberFormat(order.asset_price, 2, true, true),
              },
              {
                label: this.$t('wallet.transaction.orders.item.date'),
                value: this.getDateFromUnix(order.date),
              }
          ];
        });
      }

      return {
        title: {
          value: this.$t('wallet.transaction.orders.title'),
          hideOnMobile: false,
        },
        isRow: true,
        details: orders,
      };
    },

    assetImages() {
      let img = '';

      if(this.isOrder) {
        img = [
          {
            __typename:'IndividualAsset',
            serial:'',
            img_back: '',
            img_front:this.listItem.pair.img,
          },
        ];
      }

      return img;
    }
  },

  methods: {
    ...mapActions('wallet',[
      'retrieveSingleTransaction', 
      'resetTransactionHistory',
    ]),

    getOrdersDetails() {
      const transactionPrice = this.getOrderTransactionPrice(this.listItem.average_price);

      const averagePrice = this.transaction.assetPrice;
      const assetQtt = this.getAssetQtt(this.listItem.base_amount);
      const orderValue = formatMoney(transactionPrice);
      const proceeds = this.getProcceds();
      const fees = formatMoney(this.listItem.fee_amount);
      const submited = this.getDateFromUnix(this.listItem.submitted_time);
      const completed = !this.isActiveOrder
        ? this.getDateFromUnix(this.listItem.completed_time)
        : '-';
      const status = capitalizeWord(this.listItem.order_status);

      return {
        title: {
          value: this.singleTransactionType,
          hideOnMobile: true,
        },
        isRow: false,
        isBorderless: true,
        details: [
          [
            {
              label: this.$t('wallet.transaction.details.avg_price'),
              value: averagePrice,
            },
            {
              label: this.$t('wallet.transaction.details.asset_quantity'),
              value: assetQtt,
            },
            {
              label: this.$t('wallet.transaction.details.order_value'),
              value: orderValue,
            },
            {
              label: this.$t('wallet.transaction.details.proceeds'),
              value: proceeds,
            },
            {
              label: this.$t('wallet.transaction.details.fees'),
              value: fees,
            },
            {
              label: this.$t('wallet.transaction.details.submited'),
              value: submited,
            },
            {
              label: this.$t('wallet.transaction.details.completed'),
              value: completed,
            },
            {
              label: this.$t('wallet.transaction.details.status'),
              value: status,
            },
          ],
        ],
      };
    },

    getDepositWithdrawalDetails() {
      const alias = this.listItem.__typename === 'CirclePaymentReceipt' ? 'payment_' : 'withdrawal_';

      const transferAmount = formatMoney(this.listItem[`${alias}amount`]);
      const transferMethod = capitalizeWord(this.listItem[`${alias}type`]);
      const submited = this.$moment(this.listItem[`${alias}created_at`]).unix();
      const completed = this.$moment(this.listItem[`${alias}updated_at`]).unix();
      const status = this.getItemStatus(this.listItem.__typename).label;

      return {
        title: {
          value: '',
          hideOnMobile: true,
        },
        isRow: false,
        isBorderless: true,
        details: [
          [
            {
              label: this.$t('wallet.transaction.details.transfer_amount'),
              value: transferAmount,
            },
            {
              label: this.$t('wallet.transaction.details.transfer_method'),
              value: transferMethod,
            },
            {
              label: this.$t('wallet.transaction.details.submited'),
              value: this.getDateFromUnix(submited),
            },
            {
              label: this.$t('wallet.transaction.details.completed'),
              value: this.getDateFromUnix(completed),
            },
            {
              label: this.$t('wallet.transaction.details.status'),
              value: status,
            },
          ],
        ],
      };
    },

    getDateFromUnix(timestamp) {
      return this.$moment(timestamp * 1000).format('MM/DD/YY');
    },

    getAssetQtt(amount) {
      return `${ this.$big(amount).toFixed(3) * 100 } %`;
    },

    getProcceds() {
      let proceeds;
      let { quote_amount, base_amount, fee_amount } = this.listItem;

      switch (this.listItem.__typename) {
        case 'UserOrderReceipt':
          proceeds = formatMoney(this.listItem.proceeds);
          break;
        case 'DropReceipt':
          proceeds = this.$big(quote_amount)
            .minus(base_amount)
            .plus(fee_amount)
            .toFixed(2);

          proceeds = formatMoney(proceeds);
          break;
        default:
          proceeds = '';
      }

      return proceeds;
    },
    
    getUserOrderType() {
      const type = this.listItem.trade_type === 'limit' ? 'limit' : 'market';
      const operation = this.listItem.is_buy ? 'buy' : 'sell';

      return `${ type }_${ operation }`;
    },

    onCancelOrder() {
      this.showModal('WalletTransactionCancelOrder', {
        orderId: this.listItem.order_id
      });
    },
  },
  
  beforeDestroy() {
    this.resetTransactionHistory();
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .transaction {
    @apply grid gap-x-8;
    grid-template-columns: 1.5fr 0.5fr;
  }
}
</style>
