<template>
  <header>
    <router-link
      :to="backLink"
      :data-testid="`${ testId }__backlink`"
      :data-cy="`${ testId }__backlink`"
    >
      <IconCarretLeft 
        :data-testid="`${testId}__backlinkIcon`"
        :data-cy="`${ testId }__backlinkIcon`"
        class="transaction-trigger"
      />
    </router-link>

    <h1
      v-if="isMobileDevice"
      class="headline-small mb-s24"
    >
      {{ type }}
    </h1>

    <section :class="{ 'flex': this.isMobileDevice }">
      <SquareImage 
        v-if="isMobileDevice"
        :image="thumbnail"
        class="transaction-thumbnail"
      />

      <section :class="{ 'ml-s16': this.isMobileDevice }">
        <h1
          v-if="!isMobileDevice"
          class="headline-large mb-s8"
        >
          {{ title }}
        </h1>

        <h2
          v-if="subtitle"
          :class="[titleClasses]"
        >
          {{ subtitle }}
        </h2>
      </section>
    </section>
  </header>
</template>

<script>
import { IconCarretLeft } from '@/assets/icons';
import { SquareImage } from '@/components/misc';

export default {
  name: 'TransactionHeader',

  components: {
    IconCarretLeft,
    SquareImage,
  },

  props: {
    backLink: {
      type: String,
      default: '/wallet/',
    },

    title: {
      type: String,
      required: true,
    },

    thumbnail: String,

    type: {
      type: String,
      required: true,
    },

    subtitle: String,
    
    testId: {
      type: String,
      default: 'unamed',
    },
  },

  computed: {
    titleClasses() {
      return !this.isMobileDevice
       ? 'subheadline-large mb-s32'
       : 'subheadline-x-small';
    }
  }
};
</script>

<style scoped>
.transaction-trigger {
  @apply mb-s32;
  width: 8px;
}

.transaction-trigger path {
  stroke: var(--colors-text-body);
}

.transaction-thumbnail {
  width: 54px;
}
</style>
